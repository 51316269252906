import React from 'react';

const GallerySetting = ({ styles, formData, setFormData }) => {
  const handleChange = (e, section, index, key) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (index !== null) {
        newData[section][index][key] = value;
      } else {
        newData[section][key] = value;
      }
      return newData;
    });
  };

  return (
    <>
      <div className={styles.notice}>
        <label />
        <p>✅ 기존 제출한 사진 내에서 순서 변경 가능</p>
        <p>✅ 갤러리 사진을 타이틀 사진/디데이 배경사진으로 변경 또는 그 반대 가능</p>
        <p>
          🚫 사진 파일에 변동이 필요한 사항 불가능
          <b>*사진 파일 교체/추가 요청 시 수정 횟수 1회가 차감됩니다.</b>
        </p>
      </div>
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='backgrounds'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>배경 사진</legend>
        <div className={styles.input_wrapper}>
          <label>타이틀 사진</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData.backgrounds.title}
          />
        </div>
        <div className={styles.input_wrapper}>
          <label>디데이 배경사진</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData.backgrounds.dday}
          />
        </div>
      </fieldset>
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='gallery'>
        {/* Gallery */}
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>갤러리</legend>
        {formData.template === 'template_simple_3' && (
          <div className={styles.notice}>📢occupation 수정 시 레이아웃이 깨질 수 있으니 주의하세요.</div>
        )}
        {formData?.gallery &&
          formData.gallery.map((item, index) => (
            <div className={styles.picture_wrapper} key={item.id}>
              <div className={styles.input_wrapper}>
                <label>파일명</label>
                <input
                  style={{
                    backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                    outlineColor: `rgba(${formData.colors.main}, 1)`,
                  }}
                  type='text'
                  value={item.file}
                  onChange={(e) => handleChange(e, 'gallery', index, 'file')}
                />
              </div>
              <div className={styles.input_wrapper}>
                <label>코멘트</label>
                <input
                  style={{
                    backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                    outlineColor: `rgba(${formData.colors.main}, 1)`,
                  }}
                  type='text'
                  value={item.comment}
                  onChange={(e) => handleChange(e, 'gallery', index, 'comment')}
                />
              </div>
              {formData.template === 'template_simple_3' && (
                <div className={styles.input_wrapper}>
                  <label>배치</label>
                  <select
                    style={{
                      backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                      outlineColor: `rgba(${formData.colors.main}, 1)`,
                    }}
                    type='text'
                    value={item.occupation}
                    onChange={(e) => handleChange(e, 'gallery', index, 'occupation')}
                  >
                    <option value='1x1'>1칸</option>
                    <option value='1x2'>가로 2칸</option>
                    <option value='2x1'>세로 2칸</option>
                    <option value='2x2'>2x2 칸</option>
                  </select>
                </div>
              )}
              <hr />
            </div>
          ))}
      </fieldset>
    </>
  );
};

export default GallerySetting;
